import React, { useState, useEffect, useRef, createContext, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { ReactComponent as CameraIcon } from './icons/Camera.svg';
import { ReactComponent as MoonIcon } from './icons/Moon.svg';
import { ReactComponent as SunIcon } from './icons/Sun.svg';
import FlagNL from './icons/FlagNL';
import FlagGB from './icons/FlagGB';
import CookieConsent from './CookieConsent';
import { translations } from './translations';
import emailjs from '@emailjs/browser';

// Initialize EmailJS
emailjs.init("NQIA1jo8fzILlgSHb");

const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const AboutUs = React.lazy(() => import('./AboutUs'));
const Equipment = React.lazy(() => import('./Equipment'));
const HowItWorks = React.lazy(() => import('./HowItWorks'));

const LanguageContext = createContext();
const ThemeContext = createContext();

export { LanguageContext, ThemeContext };

const LoadingScreen = () => (
  <div className="loading">
    <div className="loading-text">FotoRent</div>
  </div>
);

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.split('-')[0];
    return savedLanguage || (browserLanguage === 'nl' ? 'nl' : 'en');
  });

  useEffect(() => {
    localStorage.setItem('language', language);
    document.documentElement.lang = language;
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });

  useEffect(() => {
    const root = window.document.documentElement;
    if (isDarkMode) {
      root.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      root.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

const Header = () => {
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const t = translations[language];

  const navItems = [
    { key: 'about', label: t.aboutUs, path: '/about' },
    { key: 'equipment', label: t.equipment, path: '/equipment' },
    { key: 'how-it-works', label: t.howItWorks, path: '/how-it-works' }
  ];

  const handleRentNow = () => {
    window.open('https://www.gearbooker.com', '_blank', 'noopener,noreferrer');
  };

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleLogoClick();
    }
  };

  const toggleLanguage = () => {
    setLanguage(lang => lang === 'en' ? 'nl' : 'en');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <motion.header 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
      className="fixed top-0 left-0 right-0 bg-primary/90 dark:bg-gray-900/90 text-white backdrop-blur-sm z-50"
    >
      <div className="container mx-auto">
        <div className="flex justify-between items-center p-4">
          <motion.div 
            className="flex items-center cursor-pointer group" 
            onClick={handleLogoClick} 
            onKeyDown={handleKeyDown}
            role="button" 
            tabIndex={0} 
            aria-label="Go to home page"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div
              whileHover={{ rotate: 180 }}
              transition={{ duration: 0.3 }}
            >
              <CameraIcon style={{color: '#FF6F3C'}} className="mr-2 transform transition-transform group-hover:rotate-12" width={36} height={36} aria-hidden="true" />
            </motion.div>
            <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-secondary to-accent">
              FotoRent
            </span>
          </motion.div>

          <nav className="hidden md:block">
            <ul className="flex space-x-8">
              {navItems.map((item) => (
                <motion.li key={item.key} whileHover={{ y: -2 }}>
                  <Link 
                    to={item.path} 
                    className="relative overflow-hidden group"
                  >
                    <span className="relative z-10 text-white/80 hover:text-white transition-colors">
                      {item.label}
                    </span>
                    <motion.span 
                      className="absolute bottom-0 left-0 w-full h-0.5 bg-secondary transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform"
                      initial={false}
                    />
                  </Link>
                </motion.li>
              ))}
            </ul>
          </nav>

          <div className="flex items-center space-x-4">
            <motion.button 
              onClick={toggleDarkMode}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="relative p-2 rounded-full bg-primary/20 backdrop-blur-sm"
              aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              <motion.div
                initial={false}
                animate={{ rotate: isDarkMode ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                {isDarkMode ? <SunIcon width={20} height={20} aria-hidden="true" /> : <MoonIcon width={20} height={20} aria-hidden="true" />}
              </motion.div>
            </motion.button>

            <motion.button 
              onClick={toggleLanguage}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="flex items-center gap-2 p-2 rounded-full bg-primary/20 backdrop-blur-sm"
              aria-label={language === 'en' ? "Switch to Dutch" : "Switch to English"}
            >
              {language === 'en' ? (
                <motion.div className="flex items-center gap-2" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <FlagNL className="w-6 h-4" aria-hidden="true" />
                  <span className="text-sm">NL</span>
                </motion.div>
              ) : (
                <motion.div className="flex items-center gap-2" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <FlagGB className="w-6 h-4" aria-hidden="true" />
                  <span className="text-sm">EN</span>
                </motion.div>
              )}
            </motion.button>

            <motion.button 
              className="bg-secondary/90 backdrop-blur-sm text-white px-6 py-2 rounded-full text-sm transition-all shadow-lg hover:shadow-secondary/20 hover:bg-secondary"
              onClick={handleRentNow}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              aria-label="Rent equipment now"
            >
              {t.rentNow}
            </motion.button>
          </div>

          <motion.button 
            className="md:hidden relative w-8 h-8"
            onClick={toggleMenu}
            aria-expanded={isMenuOpen}
            aria-label="Toggle menu"
            whileTap={{ scale: 0.9 }}
          >
            <div className="absolute inset-0 flex flex-col items-center justify-center gap-1.5">
              <motion.span
                className="block w-5 h-0.5 bg-white"
                animate={{ rotate: isMenuOpen ? 45 : 0, y: isMenuOpen ? 2 : 0 }}
              />
              <motion.span
                className="block w-5 h-0.5 bg-white"
                animate={{ opacity: isMenuOpen ? 0 : 1 }}
              />
              <motion.span
                className="block w-5 h-0.5 bg-white"
                animate={{ rotate: isMenuOpen ? -45 : 0, y: isMenuOpen ? -2 : 0 }}
              />
            </div>
          </motion.button>
        </div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="md:hidden bg-primary/95 dark:bg-gray-900/95 backdrop-blur-md"
          >
            <div className="container mx-auto p-4">
              <nav className="space-y-4">
                {navItems.map((item) => (
                  <motion.div
                    key={item.key}
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -20, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Link
                      to={item.path}
                      className="block py-2 text-lg font-medium text-white/80 hover:text-white transition-colors"
                      onClick={closeMenu}
                    >
                      {item.label}
                    </Link>
                  </motion.div>
                ))}
              </nav>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.header>
  );
};

const Footer = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <footer className="bg-primary dark:bg-gray-900 text-white py-6">
      <div className="container mx-auto px-4 text-center">
        <div className="flex justify-center items-center mb-4">
          <CameraIcon style={{color: '#FF6F3C'}} className="mr-2" width={36} height={36} aria-hidden="true" />
          <span className="text-xl font-bold">FotoRent</span>
        </div>
        <p className="text-sm mb-2">{t.footer.empowering}</p>
        <p className="text-sm">&copy; 2024 FotoRent. {t.footer.rights}</p>
        <Link to="/privacy-policy" className="text-sm text-secondary hover:underline focus:outline-none focus:ring-2 focus:ring-secondary">{t.footer.privacyPolicy}</Link>
      </div>
    </footer>
  );
};

const Hero = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { scrollY } = useScroll();
  const y = useTransform(scrollY, [0, 500], [0, -150]);
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);
  const scale = useTransform(scrollY, [0, 300], [1, 0.8]);

  return (
    <motion.section 
      className="relative min-h-screen flex items-center justify-center overflow-hidden"
      style={{ 
        y,
        backgroundImage: "url('/images/hero-background.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-black/80 via-black/50 to-transparent"></div>
      <motion.div 
        className="container mx-auto relative z-10 px-4 flex flex-col items-start"
        style={{ opacity, scale }}
      >
        <motion.div 
          className="max-w-5xl"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="mb-12">
            <motion.div 
              className="overflow-hidden mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <motion.div
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <span className="inline-block text-4xl md:text-7xl font-bold text-white">
                  Rent
                </span>
                <span className="inline-block text-4xl md:text-7xl font-bold text-secondary ml-4">
                  Professional
                </span>
              </motion.div>
            </motion.div>

            <motion.div 
              className="overflow-hidden mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              <motion.div
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <span className="block text-5xl md:text-8xl font-bold text-white">
                  Photography
                </span>
              </motion.div>
            </motion.div>

            <motion.div 
              className="overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              <motion.div
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <span className="block text-5xl md:text-8xl font-bold text-white">
                  Equipment
                </span>
              </motion.div>
            </motion.div>
          </div>
          
          <motion.div 
            className="mb-12 max-w-2xl"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
          >
            <div className="h-0.5 w-24 bg-secondary mb-8"></div>
            <p className="text-white/90 text-xl md:text-3xl font-light">
              High-quality gear for your creative projects
            </p>
            <p className="text-white/80 text-lg md:text-xl mt-4 font-light">
              in the Netherlands
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1 }}
          >
            <button 
              className="bg-secondary text-white text-lg px-12 py-4 rounded-full transition-all duration-300 shadow-lg hover:shadow-2xl hover:bg-[#ff5a1f] focus:outline-none focus:ring-2 focus:ring-secondary transform hover:scale-105"
              onClick={() => window.open('https://www.gearbooker.com', '_blank', 'noopener,noreferrer')}
            >
              {t.hero.cta}
            </button>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

const LegalInfo = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <section className="bg-background dark:bg-gray-800 py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-3xl font-bold text-primary dark:text-white mb-8">
            {t.aboutUs_legalInformation}
          </h2>
          <div className="bg-white dark:bg-gray-900 rounded-2xl p-8 shadow-lg">
            <div className="space-y-4">
              <p className="text-gray-700 dark:text-gray-300">
                {t.aboutUs_businessForm}
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                {t.aboutUs_kvkNumber}
              </p>
              <p className="text-gray-700 dark:text-gray-300">
                {t.aboutUs_vatNumber}
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

const Contact = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true);
    setError(null);

    const templateParams = {
      to_name: "Admin",
      from_name: name,
      from_email: email,
      message: message,
      reply_to: email
    };

    emailjs.send('service_0yqx87k', 'template_7bm6q98', templateParams)
      .then((result) => {
          console.log(result.text);
          setSent(true);
          setName('');
          setEmail('');
          setMessage('');
          setSending(false);
      }, (error) => {
          console.log(error.text);
          setError('There was an error sending your message. Please try again later.');
          setSending(false);
      });
  };

  return (
    <section className="bg-primary dark:bg-gray-900 text-white py-16">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="max-w-4xl mx-auto"
        >
          <h2 className="text-3xl font-bold mb-8 text-center">
            {t.contact.getInTouch}
          </h2>
          <p className="text-lg text-center mb-12 text-white/80">
            {t.contact.questions}
          </p>

          <div className="grid md:grid-cols-2 gap-12">
            <div className="space-y-8">
              <div>
                <h3 className="text-xl font-semibold mb-4">
                  {t.contact.title}
                </h3>
                <div className="space-y-4">
                  <p className="flex items-center gap-2">
                    <span className="text-secondary">•</span>
                    <span>{t.contact.email} support@fotorent.nl</span>
                  </p>
                  <p className="flex items-center gap-2">
                    <span className="text-secondary">•</span>
                    <span>{t.contact.phone} +31 (0)20 123 4567</span>
                  </p>
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-4">
                  {t.aboutUs_contactUs}
                </h3>
                <p className="text-white/80">
                  {t.aboutUs_contactUs}
                </p>
              </div>
            </div>

            <motion.form
              ref={form}
              onSubmit={sendEmail}
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <div>
                <label htmlFor="from_name" className="block text-sm font-medium mb-2">
                  {t.contact.name}
                </label>
                <input
                  type="text"
                  id="from_name"
                  name="from_name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 focus:border-secondary focus:ring-1 focus:ring-secondary outline-none transition-colors"
                />
              </div>
              <div>
                <label htmlFor="from_email" className="block text-sm font-medium mb-2">
                  {t.contact.email}
                </label>
                <input
                  type="email"
                  id="from_email"
                  name="from_email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 focus:border-secondary focus:ring-1 focus:ring-secondary outline-none transition-colors"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium mb-2">
                  {t.contact.message}
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  className="w-full px-4 py-2 rounded-lg bg-white/10 border border-white/20 focus:border-secondary focus:ring-1 focus:ring-secondary outline-none transition-colors resize-none"
                ></textarea>
              </div>
              <motion.button
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="w-full bg-secondary text-white py-3 rounded-lg hover:bg-opacity-90 transition-colors disabled:opacity-50"
                disabled={sending}
              >
                {sending ? 'Sending...' : t.contact.send}
              </motion.button>
              {sent && (
                <motion.p 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-green-500 text-center"
                >
                  Message sent successfully!
                </motion.p>
              )}
              {error && (
                <motion.p 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-red-500 text-center"
                >
                  {error}
                </motion.p>
              )}
            </motion.form>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

const HomePage = () => (
  <>
    <Hero />
    <LegalInfo />
    <Contact />
  </>
);


const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isCookieConsentActive, setIsCookieConsentActive] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <HelmetProvider>
      <Router>
        <LanguageProvider>
          <ThemeProvider>
            <div className="font-sans bg-background dark:bg-primary min-h-screen">
              <div className="noise" />
              <a href="#main-content" className="sr-only focus:not-sr-only focus:absolute focus:top-0 focus:left-0 focus:z-50 focus:bg-secondary focus:text-white focus:p-4">
                Skip to main content
              </a>
              <Header />
              <main id="main-content">
                <Suspense fallback={<LoadingScreen />}>
                  <AnimatePresence mode="wait">
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/about" element={<AboutUs />} />
                      <Route path="/equipment" element={<Equipment />} />
                      <Route path="/how-it-works" element={<HowItWorks />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    </Routes>
                  </AnimatePresence>
                </Suspense>
              </main>
              <Footer />
              {isCookieConsentActive && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-40" aria-hidden="true" />
              )}
              <CookieConsent onConsentChange={setIsCookieConsentActive} />
            </div>
          </ThemeProvider>
        </LanguageProvider>
      </Router>
    </HelmetProvider>
  );
};

export default App;
