import React from 'react';

const FlagNL = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="2" y="6" width="20" height="12" rx="2" fill="#fff"/>
    <rect x="2" y="6" width="20" height="4" fill="#AE1C28"/>
    <rect x="2" y="14" width="20" height="4" fill="#21468B"/>
  </svg>
);

export default FlagNL;
