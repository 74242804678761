import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';
import FocusTrap from 'focus-trap-react';

const CookieConsent = ({ onConsentChange }) => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
      onConsentChange(true);
    }
  }, [onConsentChange]);

  const handleClose = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowConsent(false);
    onConsentChange(false);
  };

  if (!showConsent) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40" aria-hidden="true" />
      <FocusTrap>
        <div 
          className="fixed bottom-4 right-4 bg-white dark:bg-gray-800 text-primary dark:text-white p-4 rounded-lg shadow-lg z-50 max-w-sm"
          role="dialog"
          aria-labelledby="cookie-consent-title"
        >
          <button 
            onClick={handleClose} 
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 p-1"
            aria-label="Close cookie consent"
          >
            <X size={20} aria-hidden="true" />
          </button>
          <h3 id="cookie-consent-title" className="text-lg font-bold mb-2">Cookie Notice</h3>
          <p className="text-sm mb-4">
            We use cookies to enhance your experience on our website. By continuing to use this site, you agree to our use of cookies as described in our{' '}
            <Link to="/privacy-policy" className="text-secondary hover:underline focus:outline-none focus:ring-2 focus:ring-secondary">
              Privacy Policy
            </Link>.
          </p>
          <div className="flex justify-end">
            <button 
              onClick={handleClose}
              className="bg-secondary text-white px-4 py-2 rounded-full text-sm transition duration-300 hover:bg-[#ff5a1f] focus:outline-none focus:ring-2 focus:ring-secondary"
            >
              Got it
            </button>
          </div>
        </div>
      </FocusTrap>
    </>
  );
};

export default CookieConsent;
