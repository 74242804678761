import React from 'react';

const FlagGB = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    {/* Background */}
    <rect x="2" y="6" width="20" height="12" rx="2" fill="#012169"/>
    
    {/* White diagonal stripes */}
    <path d="M2 6.5L9.5 11L22 6.5" stroke="white" strokeWidth="2"/>
    <path d="M2 17.5L9.5 13L22 17.5" stroke="white" strokeWidth="2"/>
    <path d="M22 6.5L14.5 11L2 6.5" stroke="white" strokeWidth="2"/>
    <path d="M22 17.5L14.5 13L2 17.5" stroke="white" strokeWidth="2"/>
    
    {/* Red diagonal stripes */}
    <path d="M2 6.5L9.5 11L22 6.5" stroke="#C8102E" strokeWidth="1"/>
    <path d="M2 17.5L9.5 13L22 17.5" stroke="#C8102E" strokeWidth="1"/>
    <path d="M22 6.5L14.5 11L2 6.5" stroke="#C8102E" strokeWidth="1"/>
    <path d="M22 17.5L14.5 13L2 17.5" stroke="#C8102E" strokeWidth="1"/>
    
    {/* White cross */}
    <rect x="10.5" y="6" width="3" height="12" fill="white"/>
    <rect x="2" y="10.5" width="20" height="3" fill="white"/>
    
    {/* Red cross */}
    <rect x="11" y="6" width="2" height="12" fill="#C8102E"/>
    <rect x="2" y="11" width="20" height="2" fill="#C8102E"/>
  </svg>
);

export default FlagGB;
